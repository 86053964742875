html {
	-webkit-overflow-scrolling: touch;
}

body {
	font-family: $font-base;
	font-weight: 400;
	color: $color-text;
	min-width: 320px;
	
	@include mq($from: tablet) {
	}
	
	@include mq($from: desktop) {
	}
	
	@include mq($from: wide) {
	}
}

body.no-scrolling, html.no-scrolling {
	overflow: hidden;
}

body.no-slider {

	@include mq($from: tablet) {
	}
}

html,
body {
	overflow-x: hidden;
}

img,
embed,
object,
video {
	height: auto;
	max-width: 100%;
}

iframe {
	max-width: 100%;
}

.button {

}

main {
	position: relative;
    height: 95vh;
	z-index: 5;
}

/* Structuur */
.content-row {
	position: relative;
}

.logo {
	position: absolute;
	top: 0;
	left: 50%;
	width: 130px;
	margin-left: -65px;
	padding: 50px 25px;
	padding-bottom: 25px;
	background-color: #fff;
	transition: all .5s ease-in-out;
	z-index: 50;

	img {
		width: 100%;
	}

	@include mq($from: tablet) {
		margin-left: -65px;
		width: 130px;
		padding: 50px 25px;
		padding-bottom: 25px;
	}
}

.sites {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
    height: 95vh;
}

.sites .site-panel {
	position: absolute;
	top: 0;
	bottom: 0;
	background-size: cover;
	background-position: center center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	transition: all .5s ease-in-out;
	
	&::before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba($color-secondary, .3);
		display: block;
		pointer-events: none;
		opacity: 1;
		visibility: visible;
		transition: all 1s ease-in-out;
		z-index: 1;
	}

	&:hover::before,
	&.panel-popout::before {
		opacity: 0;
		visibility: hidden;
	}

	&:first-child {
		border-right: 1px solid #fff;
		left: 0;
		right: 50%;
	}

	&:last-child {
		border-left: 1px solid $color-secondary;
		left: 50%;
		right: 0;
	}

	.site-panel-text {
		position: relative;
		background-color: #fff;
		padding: 20px;
		width: 90%;
		max-width: 500px;
		text-decoration: none;
		color: #fff;
		z-index: 5;
	}
	
	.site-title-prepend {
		text-transform: uppercase;
		font-weight: 700;
		margin-bottom: 5px;
		letter-spacing: 2px;
	}

	.site-panel-text .site-title {
		text-transform: uppercase;
		font-size: 1.2rem;
		margin: 0;
	}

	.site-panel-text .site-title-append {
		text-transform: uppercase;
		color: #000;
		font-weight: 600;
	}

	&.site-panel-consument .site-panel-text {
		background-color: $color-primary;

		.site-title-prepend {
			color: #000;
		}
	}

	&.site-panel-zakelijk .site-panel-text {
		background-color: $color-secondary;

		.site-title-prepend {
			color: $color-primary;
		}
	}

	// consumer active
	&.site-panel-consument.panel-popout {
		left: 0;
		right: 45%;
	}

	&.site-panel-zakelijk.panel-not-popout {
		right: 0;
		left: 55%;
	}

	// business active
	&.site-panel-zakelijk.panel-popout {
		left: 45%;
		right: 0;
	}

	&.site-panel-consument.panel-not-popout {
		right: 55%;
		left: 0;
	}

	@include mq($from: tablet) {

		.site-panel-text {
			padding: 25px 50px;
			background-image: url('assets/images/icon-arrow-right.svg');
			background-repeat: no-repeat;
			background-position: center right 30px;
		}

		.site-title-prepend {
			font-size: 1.3rem;
		}

		.site-panel-text .site-title {
			font-size: 2rem;
		}
	}

	@include mq($from: desktop) {
		.site-panel-text .site-title {
			font-size: 3rem;
		}

		.site-panel-text {
			padding: 35px 70px;
		}
	}
}

.content-row {

	&.consument-panel-active .logo {
		left: 55%;
		right: 45%;
	}

	&.zakelijk-panel-active .logo {
		right: 55%;
		left: 45%;
	}
}

.main-footer {
	height: auto;
	padding: 5px;
	text-align: center;

	address {
		height: 100%;
		font-style: normal;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		line-height: 2;
	}

	address > span {
		margin: 0 3px;
		width: 100%;
	}
	
	.seperator {
		display: none;
	}

	address a {
		color: $color-primary;

		> span {
			color: #000;
		}

		text-decoration: none;
	}

	@include mq($from: tablet) {
		height: 5vh;

		address > span {
			width: auto;
		}

		.seperator {
			display: block;
		}	
	}
}

@media print {
	header,
	footer,
	.slider,
	.sidebar,
	.video-footer,
	.quick-selector {
		display: none;
	}
	
	img {
		max-width: 250px;
	}
}