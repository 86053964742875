/*!
Theme Name: EVE Splash
Theme URI: eve-bv.nl
Text Domain: eve
Description: Thema voor EVE
Author: Danny Jasper
Version: 1.0
*/

// Breakpoints
$mq-responsive: true;
$mq-breakpoints: (
	mobile:  320px,
	bigmobile:  365px,
	minitablet:  480px,
	tablet:  740px,
	desktop: 975px,
	wide:    1220px,
	mobileLandscape: 668px
);
$mq-static-breakpoint: desktop;
@import 'mq';

// Standaard
@import 'vars';
@import 'normalize';

// Scripts

// Extra styles

// Globale stylesheet
@import 'global';